import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "../styles/layout.scss"
import "../styles/main.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header style={{ opacity: 0 }} siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
          display: `flex`,
          flexDirection: `column`,
          minHeight: `100vh`,
        }}
      >
        <main style={{ flexGrow: 1 }}>{children}</main>
        <footer style={{ display: `flex`, justifyContent: `space-between` }}>
          <a href="https://www.linkedin.com/in/adrian-barnes-software-engineer/">
            LinkedIn
          </a>
          <Link to="/">
            AdrianBarn<span style={{ margin: `0 -1.5px` }}>.</span>es
          </Link>
          <a href="https://twitter.com/adrian271">Twitter</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
